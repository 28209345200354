import React from 'react';
import './index.scss';

function Sounds() {
    return (
        <div id="sounds" className="sounds-container">
            <div className="content">
                <div className="mixes">
                    <div className="music_widget">
                        <iframe width="100%" height="450" allow="autoplay" title="summervibes"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1608526092&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>                        <div
                        style={{fontSize: '10px', color: '#cccccc', lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis', fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif', fontWeight: '100'}}>
                        <a href="https://soundcloud.com/djtazuk" title="DJ Taz / @djtazuk_" target="_blank" rel="noreferrer"
                           style={{color: '#cccccc', textDecoration: 'none'}}>DJ Taz / @djtazuk_</a> · <a
                        href="https://soundcloud.com/djtazuk/members-only-15th-september-2023-promo-mix-by-dj-taz"
                        title="@djtazuk_ / Members Only - 15th September 2023" target="_blank" rel="noreferrer"
                        style={{color: '#cccccc', textDecoration: 'none'}}>@djtazuk_ / Members Only
                        2023</a></div>
                    </div>
                    <div className="music_widget">
                        <iframe width="100%" height="450" allow="autoplay" title="membersonlyevent"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1274803153&color=%23ed0000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                        <div
                            style={{fontSize: '10px', color: '#cccccc', lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis', fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif', fontWeight: '100'}}>
                            <a href="https://soundcloud.com/user-77063946" title="MembersOnlyEvents" target="_blank" rel="noreferrer"
                               style={{color: '#cccccc', textDecoration: 'none'}}>MembersOnlyEvents</a> · <a
                            href="https://soundcloud.com/user-77063946/membersonlyevents-promo-mix-by-dj-taz"
                            title="MembersOnlyEvents Promo Mix By DJ Taz" target="_blank" rel="noreferrer"
                            style={{color: '#cccccc', textDecoration: 'none'}}>MembersOnlyEvents Promo Mix By DJ Taz</a>
                        </div>
                    </div>
                    <div className="music_widget">
                        <iframe width="100%" height="450" allow="autoplay" title="itsavibevol1"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/736950337&color=%23ed0000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                        <div
                            style={{fontSize: '10px', color: '#cccccc', lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis', fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif', fontWeight: '100'}}>
                            <a href="https://soundcloud.com/djtazuk" title="DJ Taz / @djtazuk_" target="_blank" rel="noreferrer"
                               style={{color: '#cccccc', textDecoration: 'none'}}>DJ Taz / @djtazuk_</a> · <a
                            href="https://soundcloud.com/djtazuk/djtazuk_its-a-vibe-vol-1"
                            title="@djtazuk_ / It&#x27;s A Vibe Vol 1" target="_blank" rel="noreferrer"
                            style={{color: '#cccccc', textDecoration: 'none'}}>@djtazuk_ / It&#x27;s A Vibe Vol 1</a>
                        </div>
                    </div>
                    <div className="music_widget">
                        <iframe width="100%" height="450" allow="autoplay" title="summervibes"
                                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/659533517&color=%23ed0000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                        <div
                            style={{fontSize: '10px', color: '#cccccc', lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis', fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif', fontWeight: '100'}}>
                            <a href="https://soundcloud.com/djtazuk" title="DJ Taz / @djtazuk_" target="_blank" rel="noreferrer"
                               style={{color: '#cccccc', textDecoration: 'none'}}>DJ Taz / @djtazuk_</a> · <a
                            href="https://soundcloud.com/djtazuk/dj-taz-summer-vibes-2k19"
                            title="@djtazuk_ / DJ Taz - Summer Vibes 2k19" target="_blank" rel="noreferrer"
                            style={{color: '#cccccc', textDecoration: 'none'}}>@djtazuk_ / DJ Taz - Summer Vibes
                            2k19</a></div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Sounds;
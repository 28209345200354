import React from 'react';
import './index.scss';
import Soundcloud from './../../../../assets/images/soundcloud.png';
import X from './../../../../assets/images/x.png';
import TikTok from './../../../../assets/images/tiktok.png';
import Snapchat from './../../../../assets/images/snapchat.png';
import MixCloud from './../../../../assets/images/mixcloud.png';
import Instagram from './../../../../assets/images/instagram.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Socials() {

    const socialData = [
        {
            icon: Soundcloud,
            title: 'SoundCloud',
            link: 'https://soundcloud.com/djtazuk'
        },
        {
            icon: X,
            title: 'X',
            link: 'https://twitter.com/djtazuk_'
        },
        {
            icon: TikTok,
            title: 'TikTok',
            link: 'https://www.tiktok.com/@djtazuk_'
        },
        {
            icon: Snapchat,
            title: 'Snapchat',
            link: 'https://msng.link/o?djtazuk=sc'
        },
        {
            icon: Instagram,
            title: 'Instagram',
            link: 'https://www.instagram.com/djtazuk_/?hl=en'
        },
        {
            icon: MixCloud,
            title: 'MixCloud',
            link: 'https://www.mixcloud.com/DJTaz_/'
        },
    ]

    const settings = {
        arrows: false,
        dots: false,
        swipeToSlide: true,
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 2000,

        responsive: [{
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                initialSlide: 2
            }
        }]
    };
    
    return (
      <div id="socials" className="socials-container">
        <div className="social__item-wrapper">
          <Slider {...settings}>
            {socialData.map((item, index) => (
              <div className="social__item">
                <a href={item.link} target="_blank" rel="noreferrer">
                  <img className="social__icon" src={item.icon} alt=""></img>
                </a>
                <h3 className="social__title">{item.title}</h3>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
}

export default Socials;
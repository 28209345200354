/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 deck.gltf --transform
Author: Milan (https://sketchfab.com/milanfonken)
License: CC-BY-NC-ND-4.0 (http://creativecommons.org/licenses/by-nc-nd/4.0/)
Source: https://sketchfab.com/3d-models/dj-set-dacd1e338270478485e35f8a79d6dc64
Title: DJ Set
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/deck-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0.4, 0]}>
        <group position={[2.22, -1.34, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder224_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder224_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-3.94, 3.72, 0.26]} scale={[0.96, 0.96, 0.69]}>
          <mesh geometry={nodes['Cylinder144_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder144_03_-_Default_0'].geometry} material={materials['03_-_Default']} />
        </group>
        <group position={[-3.94, 3.14, 0.26]} scale={[0.96, 0.96, 0.69]}>
          <mesh geometry={nodes['Cylinder145_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder145_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[-4.3, -2.63, 0.6]} rotation={[-0.26, 0, 0]} scale={0.53}>
          <mesh geometry={nodes['Box009_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box009_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[-4.3, -2.9, 0.67]} rotation={[-0.26, 0, 0]} scale={0.53}>
          <mesh geometry={nodes['Box010_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box010_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[-4.3, -3.16, 0.74]} rotation={[-0.26, 0, 0]} scale={0.53}>
          <mesh geometry={nodes['Box011_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box011_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[-4.3, -3.42, 0.81]} rotation={[-0.26, 0, 0]} scale={0.53}>
          <mesh geometry={nodes['Box012_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box012_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
        </group>
        <group position={[-4.3, -3.69, 0.88]} rotation={[-0.26, 0, 0]} scale={0.53}>
          <mesh geometry={nodes['Box013_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box013_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-5.08, -3.69, 0.88]} rotation={[-0.26, 0, 0]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box014_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box014_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[-5.81, -3.69, 0.88]} rotation={[-0.26, 0, 0]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box015_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box015_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[-6.54, -3.69, 0.88]} rotation={[-0.26, 0, 0]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box016_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box016_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[-7.28, -3.69, 0.88]} rotation={[-0.26, 0, 0]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box017_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box017_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[-3.86, -2.14, 0.28]} scale={0.53}>
          <mesh geometry={nodes['Box027_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box027_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[-3.86, -2.49, 0.28]} scale={0.53}>
          <mesh geometry={nodes['Box028_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box028_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
        </group>
        <group position={[-3.86, -2.85, 0.28]} scale={0.53}>
          <mesh geometry={nodes['Box029_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box029_08_-_Default_0'].geometry} material={materials['08_-_Default']} />
        </group>
        <group position={[-3.86, -3.21, 0.28]} scale={0.53}>
          <mesh geometry={nodes['Box030_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box030_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[-3.84, -4.02, 0.28]} scale={[0.62, 0.93, 0.53]}>
          <mesh geometry={nodes['Box031_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box031_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
        </group>
        <group position={[-3.86, -3.58, 0.28]} scale={0.53}>
          <mesh geometry={nodes['Box032_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box032_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-8.16, 2.92, 0.31]}>
          <mesh geometry={nodes['Box033_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box033_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-8.31, -2.11, 0.21]} rotation={[0, 0, -2.09]}>
          <mesh geometry={nodes['Cylinder171_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder171_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-8.31, -2.43, 0.21]} rotation={[0, 0, 1.94]}>
          <mesh geometry={nodes['Cylinder172_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder172_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-3.9, 2.52, 0.21]}>
          <mesh geometry={nodes['Cylinder173_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder173_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-3.9, 2.19, 0.21]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder174_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder174_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-5.08, -1.52, 0.25]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box034_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box034_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[-5.81, -1.52, 0.25]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box035_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box035_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[-6.54, -1.52, 0.25]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box036_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box036_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[-7.28, -1.52, 0.25]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box037_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box037_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[-7.83, -2.55, 0.51]} rotation={[-0.26, 0, 0]}>
          <mesh geometry={nodes['Cylinder175_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder175_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-7.83, -2.87, 0.59]} rotation={[-0.26, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder176_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder176_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-3.9, 1.7, 0.12]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder177_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder177_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[-3.9, 1.27, 0.12]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder178_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder178_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[-8.32, -2.96, 0.12]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder179_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder179_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[-8.32, -3.39, 0.12]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder180_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder180_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[2.76, -1.34, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder225_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder225_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.08, -0.03, -0.9]}>
          <mesh geometry={nodes['Box058_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box058_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
          <mesh geometry={nodes['Box058_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box058_08_-_Default_0'].geometry} material={materials['08_-_Default']} />
        </group>
        <group position={[-0.87, -0.66, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder198_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder198_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.87, -1.34, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder199_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder199_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.87, -2.02, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder200_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder200_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.08, -0.66, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder201_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder201_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.08, -1.34, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder202_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder202_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.08, -2.02, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder203_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder203_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[0.71, -0.66, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder204_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder204_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[0.71, -1.34, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder205_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder205_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[0.71, -2.02, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder206_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder206_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[1.49, -0.66, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder207_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder207_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[1.49, -1.34, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder208_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder208_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[1.49, -2.02, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder209_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder209_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.87, 2.58, 0.39]}>
          <mesh geometry={nodes['Box059_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box059_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.08, 2.19, 0.39]}>
          <mesh geometry={nodes['Box060_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box060_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[0.7, 2.56, 0.39]}>
          <mesh geometry={nodes['Box061_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box061_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[1.49, 2.91, 0.39]}>
          <mesh geometry={nodes['Box062_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box062_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.08, 3.84, 0.39]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes['Box063_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box063_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.86, 0.86, 0.43]} scale={[0.98, 0.98, 0.53]}>
          <mesh geometry={nodes['Box064_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box064_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[-0.08, 0.86, 0.43]} scale={[0.98, 0.98, 0.53]}>
          <mesh geometry={nodes['Box065_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box065_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[0.71, 0.86, 0.43]} scale={[0.98, 0.98, 0.53]}>
          <mesh geometry={nodes['Box066_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box066_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[1.5, 0.86, 0.43]} scale={[0.98, 0.98, 0.53]}>
          <mesh geometry={nodes['Box067_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box067_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[-0.86, 0.23, 0.43]} scale={[0.98, 0.98, 0.53]}>
          <mesh geometry={nodes['Box068_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box068_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
        </group>
        <group position={[-0.08, 0.23, 0.43]} scale={[0.98, 0.98, 0.53]}>
          <mesh geometry={nodes['Box069_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box069_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
        </group>
        <group position={[0.71, 0.23, 0.43]} scale={[0.98, 0.98, 0.53]}>
          <mesh geometry={nodes['Box070_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box070_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
        </group>
        <group position={[1.5, 0.23, 0.43]} scale={[0.98, 0.98, 0.53]}>
          <mesh geometry={nodes['Box071_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box071_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
        </group>
        <group position={[2.53, 2.74, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder210_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder210_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[2.53, 2.07, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder211_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder211_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[2.28, -2.05, 0.25]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder212_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder212_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[2.71, -2.05, 0.25]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder213_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder213_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[-2.85, 1.32, 0.42]} scale={0.53}>
          <mesh geometry={nodes['Box072_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box072_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[-2.48, 0.97, 0.26]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder214_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder214_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[-2.47, 1.32, 0.42]} scale={0.53}>
          <mesh geometry={nodes['Box073_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box073_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[-2.1, 1.32, 0.42]} scale={0.53}>
          <mesh geometry={nodes['Box074_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box074_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[-2.5, 2.9, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder215_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder215_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-2.5, 2.19, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder216_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder216_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-2.5, 1.57, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder217_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder217_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-1.63, -3.08, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder218_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder218_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-1.63, -0.12, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder219_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder219_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.87, -3.08, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder220_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder220_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-0.08, -3.08, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder221_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder221_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[0.71, -3.08, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder222_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder222_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[1.49, -3.08, 0.32]} rotation={[0, 0, -1.12]} scale={[1.32, 1.32, 1]}>
          <mesh geometry={nodes['Cylinder223_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder223_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[-6.09, 1.22, 0.16]}>
          <mesh geometry={nodes['Cylinder121_08_-_Default_0'].geometry} material={materials['08_-_Default']} />
          <mesh geometry={nodes['Cylinder121_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
          <mesh geometry={nodes['Cylinder121_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder121_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[7.88, -2.63, 0.6]} rotation={[-0.26, 0, 0]} scale={0.53}>
          <mesh geometry={nodes['Box075_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box075_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[7.88, -2.9, 0.67]} rotation={[-0.26, 0, 0]} scale={0.53}>
          <mesh geometry={nodes['Box076_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box076_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[7.88, -3.16, 0.74]} rotation={[-0.26, 0, 0]} scale={0.53}>
          <mesh geometry={nodes['Box077_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box077_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[7.88, -3.42, 0.81]} rotation={[-0.26, 0, 0]} scale={0.53}>
          <mesh geometry={nodes['Box078_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box078_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
        </group>
        <group position={[7.88, -3.69, 0.88]} rotation={[-0.26, 0, 0]} scale={0.53}>
          <mesh geometry={nodes['Box079_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box079_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[7.1, -3.69, 0.88]} rotation={[-0.26, 0, 0]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box080_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box080_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[6.37, -3.69, 0.88]} rotation={[-0.26, 0, 0]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box081_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box081_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[5.64, -3.69, 0.88]} rotation={[-0.26, 0, 0]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box082_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box082_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[4.91, -3.69, 0.88]} rotation={[-0.26, 0, 0]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box083_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box083_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[8.32, -2.14, 0.28]} scale={0.53}>
          <mesh geometry={nodes['Box084_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box084_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[8.32, -2.49, 0.28]} scale={0.53}>
          <mesh geometry={nodes['Box085_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box085_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
        </group>
        <group position={[8.32, -2.85, 0.28]} scale={0.53}>
          <mesh geometry={nodes['Box086_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box086_08_-_Default_0'].geometry} material={materials['08_-_Default']} />
        </group>
        <group position={[8.32, -3.21, 0.28]} scale={0.53}>
          <mesh geometry={nodes['Box087_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box087_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[8.35, -4.02, 0.28]} scale={[0.62, 0.93, 0.53]}>
          <mesh geometry={nodes['Box088_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box088_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
        </group>
        <group position={[8.32, -3.58, 0.28]} scale={0.53}>
          <mesh geometry={nodes['Box089_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box089_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[8.13, 2.92, 0.31]}>
          <mesh geometry={nodes['Box090_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Box090_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[7.1, -1.52, 0.25]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box091_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box091_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[6.37, -1.52, 0.25]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box092_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box092_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[5.64, -1.52, 0.25]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box093_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box093_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[4.91, -1.52, 0.25]} scale={[1.17, 0.53, 0.53]}>
          <mesh geometry={nodes['Box094_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
          <mesh geometry={nodes['Box094_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
        </group>
        <group position={[6.09, 1.22, 0.16]} scale={[-1, 1, 1]}>
          <mesh geometry={nodes['Cylinder226_08_-_Default_0'].geometry} material={materials['08_-_Default']} />
          <mesh geometry={nodes['Cylinder226_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
          <mesh geometry={nodes['Cylinder226_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder226_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[3.93, 3.72, 0.26]} scale={[0.96, 0.96, 0.69]}>
          <mesh geometry={nodes['Cylinder231_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder231_03_-_Default_0'].geometry} material={materials['03_-_Default']} />
        </group>
        <group position={[3.93, 3.14, 0.26]} scale={[0.96, 0.96, 0.69]}>
          <mesh geometry={nodes['Cylinder232_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder232_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
        </group>
        <group position={[3.87, -2.11, 0.21]} rotation={[0, 0, -2.09]}>
          <mesh geometry={nodes['Cylinder233_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder233_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[3.87, -2.43, 0.21]} rotation={[0, 0, 1.94]}>
          <mesh geometry={nodes['Cylinder234_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder234_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[3.97, 2.52, 0.21]}>
          <mesh geometry={nodes['Cylinder235_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder235_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[3.97, 2.19, 0.21]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder236_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder236_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[4.35, -2.55, 0.51]} rotation={[-0.26, 0, 0]}>
          <mesh geometry={nodes['Cylinder237_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder237_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[4.35, -2.87, 0.59]} rotation={[-0.26, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder238_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder238_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
        </group>
        <group position={[3.97, 1.7, 0.12]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder239_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder239_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[3.97, 1.27, 0.12]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder240_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder240_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[3.87, -2.96, 0.12]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder241_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder241_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <group position={[3.87, -3.39, 0.12]} rotation={[0, 0, -1.12]}>
          <mesh geometry={nodes['Cylinder242_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
          <mesh geometry={nodes['Cylinder242_14_-_Default_0'].geometry} material={materials['14_-_Default']} />
        </group>
        <mesh geometry={nodes['Cylinder123_01_-_Default_0'].geometry} material={materials['01_-_Default']} position={[-4.2, 3.52, -0.96]} />
        <mesh geometry={nodes['Cylinder124_01_-_Default_0'].geometry} material={materials['01_-_Default']} position={[-7.95, -3.63, -0.96]} />
        <mesh geometry={nodes['Cylinder125_01_-_Default_0'].geometry} material={materials['01_-_Default']} position={[-4.2, -3.63, -0.96]} />
        <mesh geometry={nodes['Cylinder122_01_-_Default_0'].geometry} material={materials['01_-_Default']} position={[-7.95, 3.52, -0.96]} />
        <mesh geometry={nodes['Cylinder227_01_-_Default_0'].geometry} material={materials['01_-_Default']} position={[4.23, 3.52, -0.96]} />
        <mesh geometry={nodes['Cylinder228_01_-_Default_0'].geometry} material={materials['01_-_Default']} position={[7.98, 3.52, -0.96]} />
        <mesh geometry={nodes['Cylinder229_01_-_Default_0'].geometry} material={materials['01_-_Default']} position={[4.23, -3.63, -0.96]} />
        <mesh geometry={nodes['Cylinder230_01_-_Default_0'].geometry} material={materials['01_-_Default']} position={[7.98, -3.63, -0.96]} />
      </group>
    </group>
  )
}

useGLTF.preload('/deck-transformed.glb')

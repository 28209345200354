import React from "react";
import About from "./Intro/About";
import Events from "./Events/Events";
import Selfridges from "./Selfridges/Selfridges";
import "./aboutPage.scss";
import Loader from "react-loaders";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

function AboutPage() {

  return (
    <>
      <div className="container about-page">
        <Splide aria-label="My Favorite Images">
          <SplideSlide>
            <About />
          </SplideSlide>
          <SplideSlide>
            <Events />
          </SplideSlide>
          <SplideSlide>
            <Selfridges />
          </SplideSlide>
        </Splide>
      </div>
      <Loader type="line-scale-pulse-out" active />
    </>
  );
}

export default AboutPage;

import React, {useRef} from "react";
import emailjs from "@emailjs/browser";
import './contact.scss';
import Map from "../../../assets/Map";
import Loader from "react-loaders";
import Footer from "../../Footer/Footer";

const Contact = () => {
    const ref = useRef();
    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_qibkn7a",
                "template_dwbryys",
                ref.current,
                "lGaUiobZKhcmf-keU"
            )
            .then(
                () => {
                    alert('Message successfully sent!')
                    window.location.reload(false)
                },
                () => {
                    alert('Failed to send the message, please try again')
                }
            );
    };
    return (
        <>
            <div className="container contact-page">
                <div className="contact-container">
                    <div className="left">
                        <form ref={ref} onSubmit={handleSubmit}>
                            <h1>Enquire Booking</h1>
                            <div className="row">
                                <input placeholder="Name" type="text" name="name" required/>
                            </div>
                            <div className="row">
                                <input
                                    placeholder="Email"
                                    type="email"
                                    name="email"
                                    required
                                />
                            </div>
                            <div className="row">
                                <input
                                    className="date-picker"
                                    placeholder="Enquiry Date"
                                    type="date"
                                    name="date"
                                    required
                                />
                            </div>
                            <div>
                                  <textarea
                                      placeholder="Write your message..."
                                      name="message"
                                      required>
                                  </textarea>
                            </div>
                            <div>
                                <input type="submit" className="flat-button" value="Send"/>
                            </div>
                        </form>
                    </div>
                    <div className="right">
                        <Map />
                    </div>
                </div>
            </div>
            <Footer />
            <Loader type="line-scale-pulse-out"  active/>
        </>

    );
}

export default Contact
import './home.scss';
import Loader from 'react-loaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInstagram,
    faXTwitter,
    faTiktok
} from '@fortawesome/free-brands-svg-icons';
import Footer from "../../Footer/Footer";

const Home = () => {
    return (
        <>
            <div className="container home-page">
                <video className="video-slide" src="/assets/video2.mp4" autoPlay loop muted playsInline></video>
                <div className="content">
                    <h1>Welcome</h1>
                    <p>Explore the world of DJ Taz</p>
                    <div className="button-container">
                        <a href="/explore">Explore More</a>
                        <a href="/contact">Book Now</a>
                    </div>
                </div>
                <div className="media-icons">
                    <a href="https://twitter.com/djtazuk_" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon
                            icon={faXTwitter}
                            color="#fff"
                            className="anchor-icon"
                        />
                    </a>
                    <a href="https://www.instagram.com/djtazuk_/?hl=en" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon
                            icon={faInstagram}
                            color="#fff"
                            className="anchor-icon"
                        />
                    </a>
                    <a href="https://www.tiktok.com/@djtazuk_" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon
                            icon={faTiktok}
                            color="#fff"
                            className="anchor-icon"
                        />
                    </a>
                </div>
            </div>
            <Footer />
            <Loader type="line-scale-pulse-out"  active/>
        </>
    )
}

export default Home
import React from 'react';
import './index.scss';
import Poster from './../../../../assets/images/bdayposter.JPG';
import Poster2 from './../../../../assets/images/bday26.jpg';

const events = [
    {
        image: Poster,
        label: '25th Bday Party'
    } ,
    {
        image: Poster2,
        label: '26th Bday Party'
    }
];


function Events() {
    return (
        <div className="events-container">
            <div className="poster_container">
                {events.map((event, index) => (
                    <div className="posters">
                        <div className="card">
                            <img className="poster" src={event.image} alt=""></img>
                            <div className="studio-button">
                                <div className="studio-button-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>
                                </div>
                                <div className="studio-button-label">
                                    {event.label}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Events;
import { Route, Routes } from 'react-router-dom';
import Home from './component/Routes/Home/Home';
import AboutPage from '../src/component/Routes/About/AboutPage';
import Contact from './component/Routes/Contact/Contact';
import Gallery from '../src/component/Routes/Gallery/Gallery';
import Explore from './component/Routes/Explore/Explore';
import Layout from '../src/component/Layout/Layout';
import './App.scss';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="explore" element={<Explore />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

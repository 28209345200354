import React from "react";
import './footer.scss';

const Footer = () => {

    const year = new Date().getFullYear();

    const link = <a id="portfolio" href={'https://ajaytaak.com/'}>ATAAK LTD</a>;

    return (
        <footer className="footer">
            <div className="container">
                <p className="copyright">Copyright {year} DJ Taz, Created by {link}. All rights reserved.{" "}</p>
            </div>
        </footer>
    )
}

export default Footer;
import './navbar.scss';
import { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import LogoS from '../../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HashLink } from "react-router-hash-link";
import {
    faBars,
    faClose,
} from '@fortawesome/free-solid-svg-icons';

const activeStyle = { color: "#000000" };

const Sidebar = () => {
    const [showNav, setShowNav] = useState(false);


    const headerRef = useRef(null);
    const menuRef = useRef(null);

    const headerFunc = () => {
        if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
            headerRef.current.classList.add("header__shrink");
        } else {
            headerRef.current.classList.remove("header__shrink");
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", headerFunc);
        return () =>
            window.removeEventListener("scroll", headerFunc);
    },[]);

    const handleClick = (e) => {
        e.preventDefault();

        const targetAttr = e.target.getAttribute("href");

        const location = document.querySelector(targetAttr).offsetTop;

        window.scrollTo({
            left: 0,
            top: location - 80,
        });
    }


    return (
      <div className="nav-bar" ref={headerRef}>
        <Link className="logo" to="/" onClick={() => setShowNav(false)}>
          <img src={LogoS} alt="Logo" />
        </Link>
        <nav className={showNav ? "mobile-show" : ""}>
          <NavLink
            exact="true"
            activeclassname="active"
            className="space linkNav"
            to="/"
            onClick={() => setShowNav(false)}
          >
            Home
          </NavLink>
          <NavLink
            activeclassname="active"
            className="explore-link linkNav"
            to="/explore"
            onClick={() => setShowNav(false)}
          >
            Explore
            <ul className="dropdown" aria-labelledby="navbarDropdown">
              <li>
                {" "}
                <HashLink
                  className="dropdown-item"
                  to="/explore#jobs"
                  activeStyle={activeStyle}
                >
                  {" "}
                  Upcoming events{" "}
                </HashLink>{" "}
              </li>
              <li>
                {" "}
                <HashLink
                  className="dropdown-item"
                  to="/explore#socials"
                  activeStyle={activeStyle}
                >
                  {" "}
                  Socials{" "}
                </HashLink>{" "}
              </li>
              <li>
                {" "}
                <HashLink
                  className="dropdown-item"
                  to="/explore#sounds"
                  activeStyle={activeStyle}
                >
                  {" "}
                  Content{" "}
                </HashLink>{" "}
              </li>
            </ul>
          </NavLink>
          <NavLink
            activeclassname="active"
            className="about-link linkNav"
            to="/about"
            onClick={() => setShowNav(false)}
          >
            About
          </NavLink>
          <NavLink
            activeclassname="active"
            className="gallery-link linkNav"
            to="/gallery"
            onClick={() => setShowNav(false)}
          >
            Gallery
          </NavLink>
          <NavLink
            activeclassname="active"
            className="contact-link linkNav"
            to="/contact"
            onClick={() => setShowNav(false)}
          >
            Booking
          </NavLink>
          <FontAwesomeIcon
            onClick={() => setShowNav(false)}
            icon={faClose}
            color="#fff"
            size="3x"
            className="close-icon"
          />
        </nav>

        <FontAwesomeIcon
          onClick={() => setShowNav(true)}
          icon={faBars}
          color="#fff"
          size="3x"
          className="hamburger-icon"
        />
      </div>
    );
}

export default Sidebar
export const gallery1 = [
    {url: require('./../assets/media/bday25th/LTJ_4722.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4729.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4801.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4852.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4875.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4878.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4883.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4891.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4931.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4990.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5000.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5019.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5037.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5059.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5067.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5070.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5082.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5092.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5099.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5114.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5121.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5125.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5130.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5150.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5164.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5166.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5188.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5196.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5213.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5219.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5223.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5238.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5252.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5273.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5275.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5299.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5304.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5314.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5328.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5341.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5350.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5360.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5377.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5392.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5409.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5420.jpg')},
]

export const gallery2 = [
    {url: require('./../assets/media/bday26th/LTJ_1885.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_1900.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_1917.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2012.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2031.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2056.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2062.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2065.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2077.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2081.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2094.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2101.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2106.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2128.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2142.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2186.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2199.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2216.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2222.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2297.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2316.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2327.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2347.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2359.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2366.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2371.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2382.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2410.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2425.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2435.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2466.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2475.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2480.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2490.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2518.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2521.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2546.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2557.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2573.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2610.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2615.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2636.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2645.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2685.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2700.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2703.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2719.jpg')},
]

export const gallery3 = [
    {url: require('./../assets/media/madeFestival/IMG2811.jpg')},
    {url: require('./../assets/media/madeFestival/IMG_2778.jpg')},
    {url: require('./../assets/media/madeFestival/IMG_2786.jpg')},
    {url: require('./../assets/media/madeFestival/IMG_2790.jpg')},
    {url: require('./../assets/media/madeFestival/IMG_2794.jpg')},
    {url: require('./../assets/media/madeFestival/IMG_2801.jpg')},
]




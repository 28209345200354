import React from 'react';
import Jobs from './Jobs/Jobs';
import Socials from './Socials/Socials';
import Sounds from './Sounds/Sounds';
import './explore.scss';
import Loader from "react-loaders";

function Explore  () {

        return (
            <>
                <div className="container explore">
                    <Jobs/>
                    <Socials/>
                    <Sounds/>
                </div>
                <Loader type="line-scale-pulse-out"  active/>
            </>

        );
}

export default Explore